.overview-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: transparent;
}

.overview-header {
  display: flex;
  justify-content: end;
  align-items: center;
  flex-basis: 8%;
}

.insights-panel {
  flex-basis: 14%;
}

.visualizer-panel {
  flex-basis: 44%;
  display: grid;
  align-items: center;
}

.graphs-panel {
  display: flex;
  height: 40vh;
  width: 100%;
  justify-content: space-around;
}

.statements-panel {
  flex-basis: 75%;
  margin: 15px;
}

.component-graph-panel {
  flex-basis: 34%;
  align-content: end;
}

.data-panel {
  flex-basis: 34%;
}

.dynamic-form-panel {
  margin: 15px;
  max-width: 100%;
  min-width: 40%;
  width: fit-content;
  box-sizing: border-box;
}

.dynamic-form-panel li {
  margin-right: 15px;
}

.data-viewport {
  display: flex;
  justify-content: space-around;
  align-content: stretch;
}

.dashboard-tables {
  flex-basis: 45%;
}

.flex-table-w35 {
  flex-basis: 35%;
}

.flex-table-w60 {
  flex-basis: 60%;
}

.flex-table-w62 {
  flex-basis: 62.5%;
}
