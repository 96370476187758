.p-datatable-header {
  background-color: var(--alpha-color);
  color: #fff;
  font-size: 1rem;
  letter-spacing: 0.02rem;
  padding: 6px;
  border-radius: 15px 15px 0px 0px;
  text-align: center;
}

.p-column-header-content {
  padding: 0px;
  font-size: 1rem;
  text-align: center;
}

.p-datatable-wrapper {
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 4px;
  font-size: 0.9rem;
}

.p-datatable {
  border-radius: 20px;
}
